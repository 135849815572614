import React from "react";
import { Section, LogoWall } from "../ui";

import awsLogo from "../logos/aws-logo.png";
import reactLogo from "../logos/react-logo.svg";
import nodejsLogo from "../logos/nodejs-logo.png";
import flowLogo from "../logos/flow-transparent-logo.png";
import typescriptLogo from "../logos/typescript-logo.svg";
import babelLogo from "../logos/babel-logo.svg";
import expressLogo from "../logos/express-logo.png";
import hapiLogo from "../logos/hapi-logo.png";
import postgresLogo from "../logos/postgres-logo.png";
import webassemblyLogo from "../logos/web-assembly-logo.png";
import graphqlLogo from "../logos/graphql-logo.png";
import webpackLogo from "../logos/webpack-logo.svg";
import jestLogo from "../logos/jest-logo.png";
import d3Logo from "../logos/d3-logo.png";
import electronLogo from "../logos/electron-logo.svg";

export const TechSection = () => (
  <Section title="Technologies We Love">
    <LogoWall>
      <img src={typescriptLogo} alt="typescript" title="typescript" />
      <img src={reactLogo} alt="react" title="react" />
      <img src={nodejsLogo} alt="nodejs" title="nodejs" />
      <img src={electronLogo} alt="electron" title="electron" />
      <img src={d3Logo} alt="d3" title="d3" style={{ width: 64 }} />
      <img src={expressLogo} alt="express" title="express" />
      <img src={hapiLogo} alt="hapi" title="hapi" />
      <img src={jestLogo} alt="jest" title="jest" />
      <img src={flowLogo} alt="flow" title="flow" />
      <img src={graphqlLogo} alt="graphql" title="graphql" />
      <img src={webassemblyLogo} alt="webassembly" title="webassembly" />
      <img src={babelLogo} alt="babel" title="babel" />
      <img src={webpackLogo} alt="webpack" title="webpack" />
      <img src={postgresLogo} alt="postgres" title="postgres" />
      <img src={awsLogo} alt="aws" title="aws" />
    </LogoWall>
  </Section>
);
