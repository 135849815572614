import * as React from "react";
import { Link, graphql, PageProps } from "gatsby";
import emotion from "@emotion/styled";

import {
  Layout,
  SEO,
  Jumbotron,
  CTABanner,
  CommentText,
  Content,
  Section,
  GrayContent,
} from "../ui";

import tsLogo from "../logos/ts-logo-512.svg";
import {
  PartnersSection,
  TechSection,
  TestimonialsSection,
  WhyCodemixSection,
} from "../sections";

const PageLink = emotion(Link)`
  display: block;
  margin-bottom: 1rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.3);
`;

const ArticleLink = emotion.a`
  display: block;
  margin-bottom: 1rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.3);
`;

const PageLinkTitle = emotion.h4`
  margin-bottom: 0;
  font-weight: normal;
  font-size: 1.6rem;
  margin-left: 0;
`;
const PageLinkDescription = emotion.p`
  color: #444;
  margin-bottom: 0.5rem;
`;

const IndexPage = ({
  data,
  location,
}: PageProps<{ site: any; allMarkdownRemark: { nodes: any[] } }>) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;
  const newPosts = posts.slice(0, posts.length - 2);
  const oldPosts = posts.slice(posts.length - 2);
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="codemix - the TypeScript experts" />
      <Jumbotron
        image={<img src={tsLogo} alt="typescript" />}
        cta={
          <CTABanner cta="Work with us" to="/contact">
            <h2>
              Building something new? Need help with an existing TypeScript
              project?
            </h2>
          </CTABanner>
        }
      >
        <h1>we are codemix, the TypeScript experts.</h1>
        <CommentText>
          we help teams large and small build very high quality applications
          with TS & JS. Frontend, Server, Mobile, IoT.
        </CommentText>
      </Jumbotron>
      <Content>
        <Section title="News & Articles">
          <ArticleLink href="https://github.com/codemix/ts-sql">
            <PageLinkTitle>
              TS-SQL: A SQL database implemented in TypeScript type annotations
            </PageLinkTitle>
            <PageLinkDescription>
              A SQL database implemented purely in TypeScript type annotations.
              Illustrates the power of TypeScript's type system
            </PageLinkDescription>
          </ArticleLink>
          {newPosts.map(post => (
            <PageLink
              key={post.frontmatter.path}
              to={post.frontmatter.path || "/"}
            >
              <PageLinkTitle>{post.frontmatter.title}</PageLinkTitle>
              <PageLinkDescription>
                {post.frontmatter.description}
              </PageLinkDescription>
            </PageLink>
          ))}
          <ArticleLink href="https://survivejs.com/blog/flow-runtime-interview/">
            <PageLinkTitle>An interview with Charles Pick</PageLinkTitle>
            <PageLinkDescription>
              codemix founder Charles Pick talks with Juho from SurviveJS about
              the future of static types in TypeScript.
            </PageLinkDescription>
          </ArticleLink>
          <ArticleLink href="https://medium.com/@gajus/ode-to-flow-runtime-ed78b2e10ec8">
            <PageLinkTitle>An Ode to Flow Runtime</PageLinkTitle>
            <PageLinkDescription>
              Gajus Kuizinas explains why he uses flow-runtime in all his open
              source projects.
            </PageLinkDescription>
          </ArticleLink>
          <ArticleLink href="https://www.youtube.com/watch?v=h-i815EifDg">
            <PageLinkTitle>Introducing Flow Runtime</PageLinkTitle>
            <PageLinkDescription>
              Patrick Stapfer does a great job explaining flow-runtime in this
              talk at React Vienna
            </PageLinkDescription>
          </ArticleLink>
          {oldPosts.map(post => (
            <PageLink
              key={post.frontmatter.path}
              to={post.frontmatter.path || "/"}
            >
              <PageLinkTitle>{post.frontmatter.title}</PageLinkTitle>
              <PageLinkDescription>
                {post.frontmatter.description}
              </PageLinkDescription>
            </PageLink>
          ))}
          <br />
          <br />
          <br />
          <p>
            Follow us on twitter for TypeScript news and updates on our
            projects: <a href="https://twitter.com/codemixers">@codemixers</a>
          </p>
        </Section>
      </Content>
      <GrayContent>
        <TechSection />
      </GrayContent>
      <Content>
        <PartnersSection />
        <TestimonialsSection />
        <WhyCodemixSection />
      </Content>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          path
        }
      }
    }
  }
`;
