import React, { ReactNode } from "react";
import emotion from "@emotion/styled";
import { Section } from "../ui";

const TestimonialQuote = emotion.blockquote`
  color: #404048;
  margin: 0;
`;

const TestimonialCite = emotion.cite`
  display: block;
  text-align: right;
`;

const TestimonialView = emotion.article`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-left: #444 solid 1rem;
  padding: 1rem;
  transition: all 0.2s linear;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Testimonial = ({
  cite,
  children,
  ...extra
}: {
  cite?: ReactNode;
  children?: ReactNode;
}) => (
  <TestimonialView {...extra}>
    <TestimonialQuote>
      {children}
      <TestimonialCite> — {cite}</TestimonialCite>
    </TestimonialQuote>
  </TestimonialView>
);

export const TestimonialsSection = () => (
  <Section title="Testimonials">
    <Testimonial cite="Brad Sands (COO, Amplifier)">
      <p>
        codemix really shone through - our team learned so much. Your spirit and
        tenacity was ultimately critical to our successful deployment.
      </p>
    </Testimonial>
    <Testimonial cite="S. Bekaert (Founder, MyHotelCard)">
      <p>
        You’d be hard pressed to find a development team which combines top
        notch programming skills with such genuine, heartfelt care and honesty.
        All the while somehow managing to completely leave out the ego, to me
        that’s the definition of true elite!
      </p>
    </Testimonial>
  </Section>
);
