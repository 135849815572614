import React from "react";
import { Section, PlainLogoWall } from "../ui";

// import dddLogo from "../logos/ddd.svg";
import rhcLogo from "../logos/rhc.png";
import oqtonLogo from "../logos/oqton.svg";
import anaplanLogo from "../logos/anaplan-logo.png";
import vimanaLogo from "../logos/vimana-logo.svg";
import catalystLogo from "../logos/catalyst-logo.svg";
import zencastrLogo from "../logos/zencastr.png";
import myhotelcardLogo from "../logos/myhotelcard-logo.png";
import pearlshareLogo from "../logos/pearlshare-logo.png";
import peopleperhourLogo from "../logos/peopleperhour-logo.png";
import ponderbankLogo from "../logos/ponderbank-logo.png";
import shareableappsLogo from "../logos/shareableapps-logo.png";
import webcertainLogo from "../logos/webcertain-logo.png";
import pumpupLogo from "../logos/pumpup-logo.png";
// import sprocketLogo from "../logos/sprocket-logo.png";
import triffiqLogo from "../logos/triffiq-logo.svg";
import cascadrLogo from "../logos/cascadr-logo.png";

export const PartnersSection = () => (
  <Section title="Our Partners">
    <p>
      Our partners like working with us because we deliver results quickly, we
      focus on excellence and make their teams even more productive. We're fun
      and easy to work with but we bring a level of energy and focus that's
      truly infectious. codemix goes above and beyond to help make sure your
      project is a spectacular success. Here are some of the companies we work
      with:
    </p>
    <PlainLogoWall>
      {/* <img src={dddLogo} /> */}
      <img src={oqtonLogo} />
      <img src={rhcLogo} />
      <img src={vimanaLogo} />
      <img src={anaplanLogo} />
      <img src={catalystLogo} />
      <img src={zencastrLogo} />
      <img src={triffiqLogo} />
      {/* <img src={sprocketLogo} /> */}
      <img src={myhotelcardLogo} />
      <img src={pearlshareLogo} />
      <img src={peopleperhourLogo} />
      <img src={ponderbankLogo} />
      <img src={cascadrLogo} />
      <img src={shareableappsLogo} />
      <img src={webcertainLogo} />
      <img src={pumpupLogo} />
    </PlainLogoWall>
  </Section>
);
